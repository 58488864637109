































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { LOGOUT_USER, USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";

export default Vue.extend({
  name: "AuthBoardingFooter",
  computed: {
    UserState() {
      return UserState;
    },
    ...mapGetters("auth", {
      user_state: USER_STATE
    })
  },
  methods: {
    ...mapActions("auth", {
      logout: LOGOUT_USER
    }),
    async _logout_user() {
      await this.logout();
      await this.$router.push("/auth/login");
    }
  }
});
